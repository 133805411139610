<template>
  <div class="bodily">
    <div class="insert-title">{{ $t('open.t3') }}</div>

    <el-form :model="formData" :rules="rules" inline ref="bodily">
      <el-form-item :label="$t('open.t30')" label-width="80px" prop="height">
        <el-input v-model="formData.height" :placeholder="$t('open.f41')"></el-input>
        cm
      </el-form-item>

      <el-form-item :label="$t('open.t31')" label-width="80px" prop="weight">
        <el-input v-model="formData.weight" :placeholder="$t('open.f42')"></el-input>
        kg
      </el-form-item>

      <el-form-item :label="$t('open.t311')" label-width="80px" prop="chest" v-if="hasFb">
        <el-input v-model="formData.chest" :placeholder="$t('open.f52')"></el-input>
        cm
      </el-form-item>

      <el-form-item :label="$t('open.t32')" label-width="80px" prop="waist">
        <el-input v-model="formData.waist" :placeholder="!hasFb ? $t('open.f43') : $t('open.f50')"></el-input>
        cm
      </el-form-item>

      <el-form-item :label="$t('open.t33')" label-width="80px" prop="hip">
        <el-input v-model="formData.hip" :placeholder="!hasFb ? $t('open.f44') : $t('open.f51')"></el-input>
        cm
      </el-form-item>

      <el-form-item :label="$t('open.t34')" label-width="80px" prop="fat" v-if="formData.type != 'M22'">
        <el-input v-model="formData.fat" :placeholder="$t('open.t35')"></el-input>
        %
      </el-form-item>
      <!-- 	<el-form-item class="qjc-wid-100 qjc-texta-c next-form-item">
				<el-button @click="next" class="next qjc-fts-16 qjc-ftw-500">下一步</el-button>
			</el-form-item> -->

    </el-form>
    <div class="footer-btn">
      <el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">{{ $t('open.t12') }}</el-button>
      <el-button @click="next" class="next qjc-fts-16 qjc-block">{{ formData.package == '5' ? $t('open.t61') :
        $t('open.t13') }}</el-button>
    </div>
  </div>
</template>

<script>
import { packageOpen } from '@/utils/axios'
import { Base64 } from 'js-base64';

let numberInput = function (value) {
  var price = '' + value;
  price = price
    .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
    .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.')
    .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
  if (price.indexOf('.') < 0 && price != '') {
    // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    price = parseFloat(price);
  }
  return price
}
export default {
  name: "bodily",
  data () {
    var heightVerify = (rule, value, callback) => {
      if (isNaN(numberInput(value)) || numberInput(value) < 130 || numberInput(value) > 200) {
        callback(new Error(this.$t('open.f54')));
      } else {
        callback();
      }
    }
    var weightVerify = (rule, value, callback) => {
      if (isNaN(numberInput(value)) || numberInput(value) < 30 || numberInput(value) > 160) {
        callback(new Error(this.$t('open.f55')));
      } else {
        callback();
      }
    }
    var chestVerify = (rule, value, callback) => {
      if (isNaN(numberInput(value)) || numberInput(value) < 40 || numberInput(value) > 165) {
        callback(new Error(this.$t('open.f56')));
      } else {
        callback();
      }
    }
    var waistVerify = (rule, value, callback) => {
      if (this.hasFb) {
        if (value != '') {
          if (isNaN(numberInput(value)) || numberInput(value) < 40 || numberInput(value) > 150) {
            callback(new Error(this.$t('open.f57')));
          } else {
            callback();
          }
        } else {
          callback(new Error(this.$t('open.f50')));
        }
      } else {
        if (value != '') {
          if (isNaN(numberInput(value)) || numberInput(value) < 40 || numberInput(value) > 150) {
            callback(new Error(this.$t('open.f57')));
          } else {
            callback();
          }
        }
        if (value == '') {
          callback();
        }
      }
    }
    var hipVerify = (rule, value, callback) => {
      if (this.hasFb) {
        if (value != '') {
          if (isNaN(numberInput(value)) || numberInput(value) < 60 || numberInput(value) > 165) {
            callback(new Error(this.$t('open.f58')));
          } else {
            callback();
          }
        } else {
          callback(new Error(this.$t('open.f51')));
        }
      } else {
        if (value != '') {
          if (isNaN(numberInput(value)) || numberInput(value) < 60 || numberInput(value) > 165) {
            callback(new Error(this.$t('open.f58')));
          } else {
            callback();
          }
        }
        if (value == '') {
          callback();
        }
      }
    }
    var fatVerify = (rule, value, callback) => {
      if (value != '') {
        if (isNaN(numberInput(value)) || numberInput(value) && numberInput(value) < 3 || numberInput(value) > 75) {
          callback(new Error(this.$t('open.f59')));
        } else {
          callback();
        }
      }
      if (value == '') {
        callback();
      }
    }

    return {
      rules: {
        height: [
          { required: true, message: this.$t('open.f39'), trigger: 'blur' },
          { validator: heightVerify, trigger: 'blur' }
        ],
        weight: [
          { required: true, message: this.$t('open.f40'), trigger: 'blur' },
          { validator: weightVerify, trigger: 'blur' }
        ],
        chest: [
          { required: true, message: this.$t('open.f53'), trigger: 'blur' },
          { validator: chestVerify, trigger: 'blur' }
        ],
        waist: [
          { required: false, message: this.$t('open.f50'), trigger: 'blur' },
          { validator: waistVerify, trigger: 'blur' }
        ],
        hip: [
          { required: false, message: this.$t('open.f51'), trigger: 'blur' },
          { validator: hipVerify, trigger: 'blur' }
        ],
        fat: [
          // { required: true, message: '请填写您的体脂率', trigger: 'blur' } ,
          { validator: fatVerify, trigger: 'blur' }
        ]
      },
      hasFb: false,
      userInfo: {}
    }
  },
  props: ['formData', 'clearData', 'type'],
  inject: ['handleRefresh'],
  mounted () {
    if (this.formData.package == '2' && this.formData.custom.indexOf('packageFB') > -1) {
      this.hasFb = true
    }
    let userInfo = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : ''

    this.userInfo = userInfo
  },
  methods: {
    //下一步
    next () {
      this.$refs.bodily.validate((isSuc, err) => {
        if (isSuc) {
          const formData = JSON.parse(JSON.stringify(this.formData))

          if (formData.package == '2' && formData.custom.indexOf('packageFB') > -1) {
            this.hasFb = true
            this.$emit('checkTab', 'InsertFb');
          } else if (formData.package == '5') {
            delete formData.qndrink
            delete formData.qnsmoke
            delete formData.qnmh
            delete formData.qnpbf
            delete formData.qntimes
            delete formData.selfcare
            delete formData.tscore
            delete formData.service
            delete formData.fbdisease
            delete formData.chest
            delete formData.examine
            delete formData.sbpCombo
            delete formData.examdate
            delete formData.examplace

            delete formData.prebust
            delete formData.prefat
            delete formData.prewaist
            delete formData.predis2
            delete formData.preweek
            delete formData.preweight
            delete formData.prehip
            delete formData.week
            delete formData.aps1
            delete formData.bsex
            delete formData.bweight
            delete formData.mage
            delete formData.sbp
            delete formData.dbp

            if (this.type == 'update') {
              formData.act = 'update'
            }
            formData.area = '4401'
            formData.operator = this.userInfo.username ? this.userInfo.username : ''
            formData.birth = formData.birth.split('/').join('')
            delete formData.package
            const type = formData.goods_type
            delete formData.goods_type

            packageOpen(formData).then(res => {
              this.submiting = false;

              if (res.code == 200) {
                // this.updateShow = false;
                if (res.data) {
                  const { id } = JSON.parse(Base64.decode(res.data))
                  this.download(type, id)
                }
                this.clearData();
                this.$emit('checkTab', 'Personal');
                this.handleRefresh()

              } else {
                // this.updateShow = false;
                if (this.formData.birth.split('/').length <= 1) {
                  let newArr = this.formData.birth.split('')
                  newArr.splice(4, 0, '/')
                  newArr.splice(-2, 0, '/')
                  this.formData.birth = newArr.join('')
                }
                this.$myAlert({
                  type: 'fail',
                  content: res.msg
                });
              }
            });
          } else if (this.formData.type == 'M22') {
            this.$emit('checkTab', 'BiochemicalM22');
          } else {
            this.$emit('checkTab', 'Biochemical');
          }
        }
      });
    },
    download (type, id) {
      console.log("查看报告", type, id);
      if (type == 'AIMB') {// 母婴
        window.open("/muying/report/lookReport.html?type=download&id=" + id);
        return
      } else if (type == 'AIZH') {
        window.open('/kyzc/lookReport.html?id=' + id);

        return
      } else if (type == 'AIFB') {
        window.open('/fb/lookReport.html?id=' + id);

        return
      } else if (type == 'AIBG') {
        window.open('/baijin/lookReport.html?id=' + id);

        return
      } else if (type == 'M22') {
        window.open('/m22/lookReport.html?id=' + id);

        return
      } else {
        let openUrl = this.$router.resolve({
          path: "/reportPrint/lookReport",
          query: {
            id: id
          }
        });
        window.open(openUrl.href);
      }
    },
    handlePrev () {
      if (this.formData.type == 'M22') {
        this.$emit('checkTab', 'Personal');
      } else {
        this.$emit('checkTab', 'Questions');
      }
    },
  }
}
</script>

<style scoped>
.bodily>>>.el-form-item__label {
  font-size: 14px;
  color: #777F8F;
  padding-right: 10px;
  text-align: left;
  display: block;
  line-height: 30px;
  float: none;
}

.bodily>>>.el-input {
  width: 400px;
  height: 32px;
  line-height: 32px;
}

.bodily>>>.el-input input {
  width: 400px;
  height: 32px;
  font-size: 14px;
  line-height: 14px;
  padding: 9px 12px;
}

.bodily>>>.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  display: none;
}

.bodily>>>.el-form-item__content {
  font-size: 14px;
}
</style>
<style lang="scss" scoped>
.bodily {
  padding-top: 50px;

  .insert-title {
    position: relative;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #293033;
    line-height: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E5E8F5;
    margin-bottom: 32px;

    &::before {
      position: absolute;
      left: -24px;
      top: 0;
      content: '';
      width: 4px;
      height: 20px;
      background-color: #6883FB;
    }
  }

  .el-form-item {
    // margin: 24px 80px 0 0;
    // min-width: 500px;
    width: 48%;


  }

  .next-form-item {
    margin-right: 0 !important;
  }

  .footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .next {
    // width: 96px;
    padding: 0 10px;
    height: 34px;
    border-radius: 17px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
  }

  .prev {
    // width: 96px;
    padding: 0 10px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #6883FB;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #6883FB;
    background-color: #fff;
    margin-right: 24px;
  }
}
</style>
